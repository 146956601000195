export const MODULE_STORE_NAME = "MODULES";

export const MODULE_ACTION_TYPE = {
  GET_MODULE_PENDING: "MODULE_ACTION_TYPE.GET_MODULE_PENDING",
  GET_MODULE_SUCCESS: "MODULE_ACTION_TYPE.GET_MODULE_SUCCESS",
  GET_MODULE_FAILURE: "MODULE_ACTION_TYPE.GET_MODULE_FAILURE",

  SET_CURRENT_MODULE: "MODULE_ACTION_TYPE.SET_CURRENT_MODULE",

  CLEAN_UP: "MODULE_ACTION_TYPE.CLEAN_UP",
};
