import axios from "axios";
import {
    getRefreshTokenFromCookies,
    getTokenFromCookies,
    removeTokensFromCookies,
    setTokenToCookies,
} from "../../utils/cookies";
import AuthService from "./auth.service";
// import TokenService from "./token.service";
const instance = axios.create({
    baseURL: "https://qcrbck.anzty.ru/",
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = getTokenFromCookies();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async(err) => {
        console.log("err: ", err);
        const originalConfig = err.config;
        console.log("originalConfig: ", originalConfig);
        const refreshToken = getRefreshTokenFromCookies();
        if (originalConfig.url !== "/auth/token/refresh/" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post("/auth/token/refresh/", {
                        refresh: refreshToken,
                    });
                    console.log("rs: ", rs);
                    const { access } = rs.data;
                    setTokenToCookies(access);

                    // TokenService.updateLocalAccessToken(access);
                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        } else if (
            originalConfig.url === "/auth/token/refresh/" &&
            err.response.status === 401
        ) {
            removeTokensFromCookies();
            AuthService.logout();
            document.location.reload();
        }
        return Promise.reject(err);
    }
);
export default instance;