export const CONSTRUCTOR_STORE_NAME = "CONSTRUCTOR";
export const CONSTRUCTOR_ACTION_TYPE = {
  GET_FOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.GET_FOLDER_PENDING",
  GET_FOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.GET_FOLDER_SUCCESS",
  GET_FOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.GET_FOLDER_FAILURE",

  ADD_FOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.ADD_FOLDER_PENDING",
  ADD_FOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.ADD_FOLDER_SUCCESS",
  ADD_FOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.ADD_FOLDER_FAILURE",

  EDIT_FOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.EDIT_FOLDER_PENDING",
  EDIT_FOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.EDIT_FOLDER_SUCCESS",
  EDIT_FOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.EDIT_FOLDER_FAILURE",

  DELETE_FOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.DELETE_FOLDER_PENDING",
  DELETE_FOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.DELETE_FOLDER_SUCCESS",
  DELETE_FOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.DELETE_FOLDER_FAILURE",

  ADD_SUBFOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.ADD_SUBFOLDER_PENDING",
  ADD_SUBFOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.ADD_SUBFOLDER_SUCCESS",
  ADD_SUBFOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.ADD_SUBFOLDER_FAILURE",

  GET_SUBFOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.GET_SUBFOLDER_PENDING",
  GET_SUBFOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.GET_SUBFOLDER_SUCCESS",
  GET_SUBFOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.GET_SUBFOLDER_FAILURE",

  EDIT_SUBFOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.EDIT_SUBFOLDER_PENDING",
  EDIT_SUBFOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.EDIT_SUBFOLDER_SUCCESS",
  EDIT_SUBFOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.EDIT_SUBFOLDER_FAILURE",

  DELETE_SUBFOLDER_PENDING: "CONSTRUCTOR_ACTION_TYPE.DELETE_SUBFOLDER_PENDING",
  DELETE_SUBFOLDER_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.DELETE_SUBFOLDER_SUCCESS",
  DELETE_SUBFOLDER_FAILURE: "CONSTRUCTOR_ACTION_TYPE.DELETE_SUBFOLDER_FAILURE",

  GET_PHRASE_PENDING: "CONSTRUCTOR_ACTION_TYPE.GET_PHRASE_PENDING",
  GET_PHRASE_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.GET_PHRASE_SUCCESS",
  GET_PHRASE_FAILURE: "CONSTRUCTOR_ACTION_TYPE.GET_PHRASE_FAILURE",

  EDIT_PHRASE_PENDING: "CONSTRUCTOR_ACTION_TYPE.EDIT_PHRASE_PENDING",
  EDIT_PHRASE_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.EDIT_PHRASE_SUCCESS",
  EDIT_PHRASE_FAILURE: "CONSTRUCTOR_ACTION_TYPE.EDIT_PHRASE_FAILURE",

  DELETE_PHRASE_PENDING: "CONSTRUCTOR_ACTION_TYPE.DELETE_PHRASE_PENDING",
  DELETE_PHRASE_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.DELETE_PHRASE_SUCCESS",
  DELETE_PHRASE_FAILURE: "CONSTRUCTOR_ACTION_TYPE.DELETE_PHRASE_FAILURE",

  ADD_PHRASE_PENDING: "CONSTRUCTOR_ACTION_TYPE.ADD_PHRASE_PENDING",
  ADD_PHRASE_SUCCESS: "CONSTRUCTOR_ACTION_TYPE.ADD_PHRASE_SUCCESS",
  ADD_PHRASE_FAILURE: "CONSTRUCTOR_ACTION_TYPE.ADD_PHRASE_FAILURE",

  CLEAN_UP: "CONSTRUCTOR_ACTION_TYPE.CLEAN_UP",
};
