export const HEADER_STORE_NAME = "HEADER";
export const HEADER_ACTION_TYPE = {
  LOGIN_PENDING: "HEADER_ACTION_TYPE.LOGIN_REQUEST",
  LOGIN_FAILURE: "HEADER_ACTION_TYPE.LOGIN_FAILURE",
  LOGIN_SUCCESS: "HEADER_ACTION_TYPE.LOGIN_SUCCESS",

  REFRESH_SUCCESS: "HEADER_ACTION_TYPE.REFRESH_SUCCESS",
  REFRESH_FAILURE: "HEADER_ACTION_TYPE.REFRESH_FAILURE",

  LOGOUT: "HEADER_ACTION_TYPE.LOGOUT",
};
