export const LESSON_ACTION_TYPE = {
  GET_LESSON_PENDING: "LESSON_ACTION_TYPE.GET_LESSON_PENDING",
  GET_LESSON_SUCCESS: "LESSON_ACTION_TYPE.GET_LESSON_SUCCESS",
  GET_LESSON_FAILURE: "LESSON_ACTION_TYPE.GET_LESSON_FAILURE",

  SET_CURRENT_LESSON: "LESSON_ACTION_TYPE.SET_CURRENT_LESSON",

  CLEAN_UP: "LESSON_ACTION_TYPE.CLEAN_UP",
};

export const LESSON_STORE_NAME = "LESSON";
